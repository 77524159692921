import React from 'react';
// CSS
import './Home.css';

function Home() {
  return (
    <div className="home-container">
      <div className="home-content">
        <span className="home-title">
          FINANCIAL<br />
          &nbsp;&nbsp;DETECTIVE
        </span>
      </div>
    </div>
  );
}

export default Home;
